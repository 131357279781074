import {DocumentType} from '@aws-amplify/core/internals/utils';

export namespace PrivateEventUserSubscriptionBean {
    export interface Model {
        code: string;
        subscriptionType: string;
    }

    export function toDocumentType(bean: Model): DocumentType {
        return bean as any as DocumentType;
    }
}
