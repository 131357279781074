import React, { useEffect, useRef, useState } from "react";
import { format, parseISO } from "date-fns";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonLabel,
    IonList,
    IonIcon,
    IonText,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle, IonButtons, IonContent
} from "@ionic/react";
import {refresh, swapVertical, close} from 'ionicons/icons';
import { DATEFORMATSTRING, TIMEFORMATSTRING } from "../../constants";
import { PulseStoreActions } from "../../store/PulseStore";
import { EventStoreActions } from "../../store/EventStore";
import { PulseViewModel } from "../../viewModel/PulseView.model";
import './PulseList.scss';
import {splitText} from '../../utilities/PulseMessageHelper';
import ReportPulseButton from "../ReportPulse/ReportPulseModal";

interface PulseListProps {
    eventId: number;
    pulseId?: number;
}

const PulseList: React.FC<PulseListProps> = (props) => {
    const {eventId, pulseId} = props;

    const [ start, finished, result, loading ] = PulseStoreActions.findAllPulsesByEventId.useWatch({ eventId });
    const pulses = result.payload?.pulses;

    const [newPulseCount, setNewPulseCount] = useState<number>(0);
    const [isReversed, setIsReversed] = useState(true);
    const lastCheckedTimeRef = useRef<Date | null>(null);
    const [showPulseDetail, setShowPulseDetail] = useState<boolean>(false);
    const [pulseDetail, setPulseDetail] = useState<PulseViewModel | undefined>(undefined);

    // Initial data fetching and setup
    useEffect(() => {
        fetchPulses();

        const interval = setInterval(checkForNewPulses, 15000); // periodic check for new pulses

        return () => {
            clearInterval(interval);
        };
    }, []);

    // Fetch initial pulse list
    const fetchPulses = async () => {
        //use isLoading
        // setLoading(true);
        try {
             await PulseStoreActions.findAllPulsesByEventId.run({ eventId });
            lastCheckedTimeRef.current = new Date();
        } catch (error) {
            console.error("Error fetching pulses:", error);
        } finally {
            // setLoading(false);
        }
    };

    // Check for new pulses since the last checked time
    const checkForNewPulses = async () => {
        if (!lastCheckedTimeRef.current) return;
        try {
            const { payload } = await EventStoreActions.findNewEventPulsesCountAction.run({
                lastCheckedTime: lastCheckedTimeRef.current.toISOString(),
                eventId,
            });
            setNewPulseCount(payload?.newEventPulseCount ?? 0);
        } catch (error) {
            console.error("Error checking for new pulses:", error);
        }
    };

    // Manually refresh pulse list
    const refreshPulses = async () => {
        await fetchPulses(); // Reload pulses
        setNewPulseCount(0); // Reset new pulse count
    };

    const toggleOrder = () => {
        setIsReversed(!isReversed);
    };

    useEffect(() => {
        if (pulseId != null) {
            const targetElement = document.getElementById(pulseId.toString(10))
            if (targetElement != null) {
                targetElement.scrollIntoView({behavior: 'smooth', block: 'center'});
                targetElement.classList.add('pulseItem', 'pulseItemFlash');
                setPulseDetail(pulses?.find(p => p.pulseId === pulseId));
                setTimeout(() => targetElement.classList.remove('pulseItemFlash'), 5000);
                setTimeout(() => setShowPulseDetail(true), 0);
                setTimeout(() => targetElement.classList.remove('pulseItem'), 15000);
            }
        }
    }, [pulseId, pulses]);

    const getDateTimeString = (pulseDetail: PulseViewModel | undefined) => {
        if (pulseDetail) {
            const dateObj = parseISO(pulseDetail?.postDate);
            const formattedDate = format(dateObj, DATEFORMATSTRING);
            const formattedTime = format(dateObj, TIMEFORMATSTRING);
            return `${formattedDate}, ${formattedTime}`
        }
        return null;
    }

    return (
        <>
            <IonCard color="secondary" style={{ display: 'flex', flexDirection: 'column', height: '94%', marginTop: "10px", marginBottom: "10px" }}>
            <IonCardHeader>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <IonCardTitle>Pulses</IonCardTitle>
                        <IonText style={{marginLeft: "8px"}}>({pulses?.length})</IonText>
                    </div>
                    <IonButton
                        style={{cursor: "pointer", fontSize: "0.92em", position: "absolute", left: "45%"}}
                        onClick={refreshPulses}
                        color="tertiary"
                        >
                        {newPulseCount == 1 ? `${newPulseCount} new pulse!` :
                            newPulseCount > 1 ? `${newPulseCount} new pulses!` : 'Refresh'}
                        <IonIcon icon={refresh}/>
                    </IonButton>
                    <IonButton fill="clear" onClick={toggleOrder}>
                        <IonLabel style={{ fontSize: "0.84em", fontWeight: "bold" }}>{isReversed ? "Newest" : "Oldest"}</IonLabel>
                        <IonIcon icon={swapVertical} />
                    </IonButton>
                </div>
            </IonCardHeader>
            <IonCardContent style={{flexGrow: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
                {loading && <p>Loading pulses...</p>}
                {!loading && (!pulses || pulses.length === 0) && <p>No pulses to display</p>}
                {!loading && pulses && pulses?.length > 0 && (
                    <div style={{flexGrow: 1, overflowY: 'auto'}}>
                        {renderEventPulseList(pulses,(x: PulseViewModel) => {
                                setPulseDetail(x);
                                setShowPulseDetail(true);
                            }, isReversed)}
                        </div>
                    )}
                </IonCardContent>
            </IonCard>
            <IonModal isOpen={showPulseDetail} onDidDismiss={() => setShowPulseDetail(false)}>
                <IonHeader>
                    <IonHeader>
                        <IonToolbar color="primary" >
                            <IonTitle class="ion-text-center">Pulse Detail</IonTitle>
                            <IonButtons slot={"end"}>
                                <IonButton shape="round" fill="clear" mode="ios" color="light"  onClick={() => setShowPulseDetail(false)}>
                                    <IonIcon icon={close} style={{fontSize: '28px'}}/>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                </IonHeader>
                <IonContent>
                    <IonCard color="secondary" >
                        <IonCardHeader>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <IonCardTitle style={{fontSize:'1.5em'}}>{pulseDetail?.pulseName}</IonCardTitle>
                            </div>
                        </IonCardHeader>
                        <IonCardContent color="light">
                            <div className={'pulse-detail-bg'}>
                                <p style={{marginTop: "4px"}}>{getDateTimeString(pulseDetail)}</p>
                                <p>From: {pulseDetail?.announcerFirstName} {pulseDetail?.announcerLastName}</p>
                                <pre className={'preWrap'}><IonText>{pulseDetail?.pulseMessage}</IonText></pre>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonToolbar>
                        <IonButtons slot={"end"}>
                            <ReportPulseButton pulseId={pulseDetail?.pulseId} eventId={pulseDetail?.eventId}
                                               completed={() => setShowPulseDetail(false)}/>
                        </IonButtons>
                    </IonToolbar>
                </IonContent>


            </IonModal>
        </>
    );
};

// Function to render the list of pulses
const renderEventPulseList = (pulses: PulseViewModel[], itemClick: (x: PulseViewModel) => void, isReversed: boolean) => {
    const displayPulses = isReversed ? pulses.slice().reverse() : pulses;

    return (
        <IonList lines="full">
            {displayPulses.map((pulse: PulseViewModel) => {
                const dateObj = parseISO(pulse.postDate);
                const formattedDate = format(dateObj, DATEFORMATSTRING);
                const formattedTime = format(dateObj, TIMEFORMATSTRING);
                const split = splitText(pulse.pulseMessage);
                return (
                    <IonItem key={pulse.pulseId}
                             id={pulse.pulseId.toString(10)}
                             className={'pulseListItem'}
                             onClick={() => itemClick(pulse)}>
                        <IonLabel>
                            <IonText style={{fontSize: "0.9em", fontWeight: "bold"}}>{pulse.pulseName}</IonText>
                            <br></br>
                            <IonText>{split.text}{split.wasSplit && <span className={'muted'}>&nbsp;Read More</span>}</IonText>
                            <p style={{marginTop: "4px"}}>{formattedDate}, {formattedTime}</p>
                            <p>From: {pulse.announcerFirstName} {pulse.announcerLastName}</p>
                            {pulse.pulseCategory && <p>Category: {pulse.pulseCategory}</p>}
                        </IonLabel>
                    </IonItem>
                );
            })}
        </IonList>
    );
};

export default PulseList;
