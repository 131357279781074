import React, {useState} from 'react';
import {IonButton, IonIcon} from '@ionic/react';
import {addOutline} from 'ionicons/icons';
import PrivateEventModal from './PrivateEventModal';

const PrivateEventCommonButton: React.FC = () => {
    const [showPrivateEventModal, setShowPrivateEventModal] = useState<boolean>(false);

    const privateEventModalOnDidDismissFn = () => setShowPrivateEventModal(false);

    return (
        <>
            <IonButton onClick={() => setShowPrivateEventModal(true)}>
                <IonIcon icon={addOutline} slot={'start'}></IonIcon>
                Private Event
            </IonButton>
            <PrivateEventModal showPrivateEventModal={showPrivateEventModal} onDidDismiss={privateEventModalOnDidDismissFn} />
        </>
    );
}

export default PrivateEventCommonButton;
