import React, { useState } from "react";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonButton,
    IonModal, IonHeader, IonToolbar, IonTitle, IonLabel
} from "@ionic/react";
import { chevronDownOutline, chevronUpOutline, attachOutline, close } from 'ionicons/icons';
import { EventUserListViewModel } from "../../viewModel/EventUserListViewModel";
import { EventStore } from "../../store/EventStore";
import { format, parseISO, isSameDay } from "date-fns";
import SubscriptionToggleButton from "./SubscriptionToggleButton";

interface EventDescriptionCardProps {
    event: EventUserListViewModel;
    orgId: number;
    subscribed: boolean;
}

const EventDescriptionCard: React.FC<EventDescriptionCardProps> = ({ event, orgId, subscribed }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showAttachments, setShowAttachments] = useState(false);
    const event1 = EventStore.useState((s) => s.events.find((o) => o.eventId === event?.eventId));

    const formatDateRange = (startDate: string, endDate: string) => {
        const start = parseISO(startDate);
        const end = parseISO(endDate);

        if (isSameDay(start, end)) {
            const date = format(start, "MMM d, yyyy");
            const startTime = format(start, "h:mm a");
            const endTime = format(end, "h:mm a");
            const startMeridiem = format(start, "a");
            const endMeridiem = format(end, "a");

            if (startMeridiem === endMeridiem) {
                return `${date}, ${format(start, "h:mm")} - ${format(end, "h:mm")}${endMeridiem}`;
            } else {
                return `${date}, ${startTime} - ${endTime}`;
            }
        } else {
            return `${format(start, "MMM d, yyyy, h:mm a")} - ${format(end, "MMM d, yyyy, h:mm a")}`;
        }
    };

    if (event1) {
        return (
            <>
                <IonCard color="secondary" style={{ marginBottom: "1px", marginTop: "15px" }}>
                    <IonCardHeader style={{ paddingBottom: '0px' }} onClick={() => setIsOpen(!isOpen)}>
                        <span style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{display: "flex"}}>
                                <IonCardTitle style={{fontWeight: "bold", marginTop: "2px", fontSize: "1.7em"}}>
                                    {event1.eventName}
                                </IonCardTitle>
                            </span>
                            <span style={{display: "flex", alignItems: "center"}}>
                                <IonLabel style={{marginRight: "-9px", marginLeft: "14px", fontWeight: "bold"}} color="tertiary">
                                    {isOpen ? "Less" : "More"}
                                </IonLabel>
                                <IonIcon
                                    slot={"end"}
                                    icon={isOpen ? chevronUpOutline : chevronDownOutline}
                                    color="tertiary"
                                    style={{fontSize: "2em", marginLeft: "10px", marginRight: "8px"}}
                                />
                            </span>
                        </span>
                    </IonCardHeader>
                    <IonCardHeader style={{marginTop: "-26px", marginBottom: "-26px"}}
                                   onClick={() => setIsOpen(!isOpen)}>
                        <p style={{fontWeight: "bold", fontSize: "1.1em", marginTop: "8px", marginBottom: "-2px"}}>
                            Event No. {event1.eventNumber}
                        </p>
                    </IonCardHeader>
                    <IonCardHeader
                        style={{marginTop: "-5px", paddingTop: "0px", paddingBottom: "2px", fontSize: "0.95em" }} onClick={() => setIsOpen(!isOpen)}>
                        <p>
                            {event1?.startDate && event1?.endDate ? formatDateRange(event1.startDate, event1.endDate) : ''}
                        </p>
                    </IonCardHeader>

                    {isOpen && (
                        <IonCard style={{marginTop:"1px", marginBottom:"10px"}} color="dark">
                            <IonCardContent>
                                <p style={{marginTop:"-5px"}}><strong>Contact:</strong> {event1?.email}</p>
                                <p style={{marginTop:"14px"}}>{event1?.description}</p>
                                <IonButton
                                    expand="block"
                                    color="tertiary"
                                    onClick={() => setShowAttachments(true)}
                                    style={{
                                        marginTop: "17px",
                                        marginBottom: "0px",
                                        fontSize: '0.9em',
                                        height: '30px',
                                        width: '11em'
                                    }}>
                                    <IonIcon icon={attachOutline} slot="start" />
                                    Attachments
                                </IonButton>
                                <br></br>
                                <SubscriptionToggleButton
                                    eventId={event1.eventId}
                                    subscribed={event1.subscribed}
                                    subscriptionType={event1.subscriptionType}
                                    eventNumber={event1.eventNumber}
                                />
                            </IonCardContent>
                        </IonCard>
                    )}

                </IonCard>

                {/*Attachments Modal*/}
                <IonModal isOpen={showAttachments} onDidDismiss={() => setShowAttachments(false)}>
                    <IonCard color="secondary">
                        <IonHeader>
                            <IonToolbar color="primary" style={{ position: 'relative' }}>
                                <IonTitle class="ion-text-center">
                                    Attachments
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonCardHeader>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <IonCardTitle style={{fontSize:"1.5em"}}>Files from {event.eventName}</IonCardTitle>
                                <IonButton
                                    shape="round"
                                    fill="clear"
                                    mode="ios"
                                    color="light"
                                    style={{marginRight: "-15px"}}
                                    onClick={() => setShowAttachments(false)}
                                >
                                    <IonIcon icon={close} style={{fontSize: "28px"}}/>
                                </IonButton>
                            </div>
                        </IonCardHeader>
                        <IonCardContent>
                            Event No. {event1.eventNumber}
                            {/* COMPONENT WILL GO HERE */}
                            <p>No attachments available.</p>
                        </IonCardContent>
                    </IonCard>
                </IonModal>
            </>
        );
    }
    return null;
};

export default EventDescriptionCard;