import './App.scss';

import {
    IonApp,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    setupIonicReact
} from '@ionic/react';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import '@aws-amplify/ui-react/styles.css';
/* Theme variables */
// import './theme/variables.css';
import Tabs from './Tabs';
import {Authenticator, CheckboxField, useAuthenticator} from "@aws-amplify/ui-react";
import {Amplify} from "aws-amplify";

setupIonicReact();


Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID as string,
            userPoolId: import.meta.env.VITE_USER_POOL_ID as string,
            identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID as string,
            mfa: {status: "off"},
            signUpVerificationMethod: "code"
        }
    },
    API: {
        REST: {
            "API": {
                endpoint: import.meta.env.VITE_ENDPOINT as string,
                region: import.meta.env.VITE_GATEWAY_REGION as string
            }
        }
    }
});



//Amplify.configure(awsExports);


//registerInDevtools({OrganizationStore})

const formFields = {
  signUp: {
      "given_name": {label: 'First Name', isRequired: true},
      "family_name": {label: 'Last Name', isRequired: true},
      "email": { isRequired: true},
      "password": {
          descriptiveText: '(8+ characters, mixed cases, numbers & special characters)',
          isRequired: true
      }
  }
}


const App: React.FC = () => (
    <IonApp className="full-viewport-height">
      <IonPage>

        <IonContent fullscreen className="ion-padding">

          <Authenticator
              loginMechanisms={["email"]}
              signUpAttributes={['email',  "given_name","family_name"]}
              formFields={formFields}
              initialState="signIn"
              components={{Header() {
                  return (<IonHeader>
                    <IonToolbar color="primary">
                      <IonTitle>Event Pulse</IonTitle>
                    </IonToolbar>
                  </IonHeader>)
                },
                SignIn: {

                },
                SignUp: {
                  FormFields() {
                    const {validationErrors} = useAuthenticator();

                    return (
                        <>
                          <Authenticator.SignUp.FormFields/>

                          <CheckboxField
                              errorMessage={(String(validationErrors.acknowledgement))}
                              hasError={!!validationErrors.acknowledgement}
                              name="acknowledgement"
                              value="yes"
                              label="I agree with the Terms & Conditions"
                              color={"black"}
                          />
                        </>
                    );
                  },
                },
              }}
              services={{
                async validateCustomSignUp(formData) {
                  if (!formData.acknowledgement) {
                    return {
                      acknowledgement: 'You must agree to the Terms & Conditions',
                    };
                  }
                },
              }}
          >
            {({signOut, user}) => (
                  <Tabs signOut={signOut}/>
            )}
          </Authenticator>
        </IonContent>
      </IonPage>
    </IonApp>
);

export default App;
