import {
    IonContent,
    IonButton,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonCard,
    IonList,
    IonItem,
    IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle, isPlatform, IonLabel, IonIcon
} from '@ionic/react';
import './Settings.css';
import { useHistory } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {UserStore} from "../store/UserStore";
type HomePageProps = {signOut:any}
import { App } from '@capacitor/app';
import {openOutline, returnUpBack} from "ionicons/icons";
import {getPlatformId} from "@capacitor/core/types/util";
import {Capacitor} from "@capacitor/core";
import {get} from "aws-amplify/api";
import packageJson from '../../package.json';
import PrivateEventModal from '../components/PrivateEvents/PrivateEventModal';


const Settings: React.FC<HomePageProps> = (props) => {
    const {signOut} = props
    const [appBuild, setAppBuild] = useState("");
    const [appVersion, setAppVersion] = useState("");
    const [showPrivateEventModal, setShowPrivateEventModal] = useState<boolean>(false);

    const history = useHistory();
    const user = UserStore.useState(s => s.user);

    const handleSignOut = () => {
        signOut();
        history.push('/myevents');
    }

    useEffect(() => {
        async function getAppInfo () {
            if (Capacitor.isNativePlatform()) {
                 const temp = await App.getInfo();
                 setAppBuild(temp.build);
                 setAppVersion(temp.version);
            }
        }

        getAppInfo();
    },[]);

    const privateEventModalOnDidDismissFn = () => setShowPrivateEventModal(false);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <img src="/EP-logo.png" alt="Logo" className="logo" style={{ height: '32px' }} />
                    <IonTitle class="ion-text-center">
                        Settings
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
                <IonCard color="secondary">
                    <IonCardHeader>
                        <IonCardTitle>Your Settings</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonList>
                            <IonItem>
                                Account Preferences
                            </IonItem>
                            <IonItem onClick={() => setShowPrivateEventModal(true)}>
                                Subscribe to Private Event
                            </IonItem>
                            <IonItem onClick={() => window.open("https://www.eventpulse.app/legal-documents")}>
                               <IonLabel>View Legal Documents</IonLabel>
                                <IonIcon icon={openOutline} slot="end"/>
                            </IonItem>
                            <IonItem>
                                Code Version: {packageJson.version}
                            </IonItem>
                            <IonItem>
                                App Version: {!!appVersion ? <h4>{appVersion}</h4> : <h4>N/A</h4> }
                            </IonItem>
                            <IonItem>
                                App Build: {!!(appBuild) ? <h4>{appBuild}</h4> : <h4>N/A</h4> }
                            </IonItem>
                            <IonItem>
                                <IonButton color="tertiary" onClick={handleSignOut} style={{marginLeft: "0px"}}>Sign Out</IonButton>
                                <h4>&nbsp;&nbsp;({user?.lastName} {user?.firstName})</h4>
                            </IonItem>
                        </IonList>
                    </IonCardContent>
                </IonCard>
            </IonContent>
            <PrivateEventModal showPrivateEventModal={showPrivateEventModal} onDidDismiss={privateEventModalOnDidDismissFn} />
        </IonPage>
    );
};

export default Settings;
