export interface SplitTextModel {
    text: string;
    wasSplit: boolean;
}

export function splitText(text: string, maxLength: number = 300, maxLengthVariability: number = 5): SplitTextModel {
    // computed constants
    const variabilityLimit = Math.floor(maxLength * (maxLengthVariability / 100));

    // tracked variables
    let splitIndex = maxLength;
    let wasSplit = false;

    // if text is empty just return as is
    if (text == null || text.trim() === '') {
        return {
            text,
            wasSplit
        };
    }

    if (text.length > maxLength) {
        const spaceIndex = text.indexOf(' ', maxLength);
        // space is on or after the maxLength
        if (spaceIndex !== -1) {
            splitIndex = spaceIndex;
            wasSplit = true;
        } else {
            // no space found, but length within the variability limit, so just return it as is
            if (text.length <= maxLength + variabilityLimit) {
                return {
                    text,
                    wasSplit: false
                }
            }
            // no space was found (might be all character text), so just split at 300 (default split index)
            wasSplit = true;
        }
    }

    // cut off long text and add ellipsis if needed, if text < maxLength then slice will adjust for us
    return {
        text: text.slice(0, splitIndex).trim() + (wasSplit ? '...' : ''),
        wasSplit
    };
}
