import {
    IonButton,
    IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonContent,
    IonHeader,
    IonIcon,
    IonLabel, IonLoading, IonPage,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs, IonTitle,
    IonToolbar
} from "@ionic/react";
import {Redirect, Route, useHistory} from "react-router-dom";
import Discover from "./pages/Discover";
import MyEvents from "./pages/MyEvents";
import Settings from "./pages/Settings";
import {people, reload, search, settings} from "ionicons/icons";
import {IonReactRouter} from "@ionic/react-router";
import EventDetail from "./pages/EventDetail";
import {UserStoreActions} from './store/UserStore';
import {useEffect, useState} from "react";
import {NotificationService} from './services/NotificationService';

type HomeProps = {
    signOut:any
}

const Tabs: React.FC<HomeProps> = ({signOut}) => {
    const [retryCount, setRetryCount] = useState(0);
    const [finishedEvents, result] = UserStoreActions.whoAmI.useBeckon();
    const [showLoading, setShowLoading] = useState(true);
    // const history = useHistory();

    useEffect(() => {
        if (finishedEvents) {
            setShowLoading(false);
        }
    }, [finishedEvents]);

    useEffect(() => {
        if (finishedEvents && result.error && retryCount === 0) {
            // First failure, retry once
            setRetryCount(1);
            setShowLoading(true);
            UserStoreActions.whoAmI.clearCache();
        }
    }, [finishedEvents, result.error, retryCount]);

    const handleRetry = () => {
        setRetryCount(prev => prev + 1);
        setShowLoading(true);
        UserStoreActions.whoAmI.clearCache();
    };

    if (showLoading && !finishedEvents) {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonTitle>Event Pulse</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonLoading
                    isOpen={true}
                    message="Loading your profile..."
                    spinner="crescent"
                    duration={0}
                    backdropDismiss={false}
                />
            </IonPage>
        );
    }

    if (finishedEvents && result.error) {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonTitle>Event Pulse</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonCard color="secondary">
                        <IonCardHeader>
                            <IonCardTitle>Sorry, something went wrong!</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <p>Failed to load profile. Please try again.</p>
                            <IonButton expand="block" onClick={handleRetry} color={"tertiary"} style={{ marginTop: '15px' }}>
                                <IonIcon slot="start" icon={reload} />
                                Retry
                            </IonButton>
                        </IonCardContent>
                    </IonCard>
                </IonContent>
            </IonPage>
        );
    }

    return (
        <IonReactRouter >
            <IonTabs >
                <IonRouterOutlet >
                    <Route exact path="/discover" component={Discover}></Route>
                    {/*<Route exact path="/home/organization/:orgId" component={OrganizationDetail}/>
                    <Route exact path="/home/organization/:orgId/:eventId" component={EventDetail}></Route>*/}
                    <Route path="/discover/event/:eventId" component={EventDetail}></Route>
                    <Route exact path="/myevents" component={MyEvents}></Route>
                    <Route path="/settings" render={()=><Settings signOut={signOut}/>}></Route>
                    <Route exact path="/">
                        <Redirect to="/myevents" />
                    </Route>
                    {/* Route for testing - change redirect as required */}
                    {/*<Route path="/test" render={() => <Redirect to="/discover/event/26?pulse=140" />}></Route>*/}
                </IonRouterOutlet>
                <IonTabBar slot="bottom" color="primary">
                    <IonTabButton tab="myevents" href="/myevents">
                        <IonIcon aria-hidden="true" icon={people} />
                        <IonLabel>My Events</IonLabel>
                    </IonTabButton>
                    <IonTabButton  tab="discover" href="/discover">
                        <IonIcon aria-hidden="true" icon={search} />
                        <IonLabel>Discover</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="settings" href="/settings">
                        <IonIcon aria-hidden="true" icon={settings} />
                        <IonLabel>Settings</IonLabel>
                    </IonTabButton>
                    {/* The following is for testing a route hook by a button click from the app's root level */}
                    {/*<IonTabButton tab="test" href="/test">*/}
                    {/*    <IonIcon aria-hidden="true" icon={settings} />*/}
                    {/*    <IonLabel>TEST</IonLabel>*/}
                    {/*</IonTabButton>*/}
                </IonTabBar>
            </IonTabs>
            <NotificationHooks />
        </IonReactRouter>
    );
};

// const TestRouteHook: React.FC = () => {
//     console.info('test hook enter');
//     const history = useHistory();
//     const click = () => {
//         history.push('/discover/event/26?pulse=140');
//     }
//     return (<></>);
// }

const NotificationHooks: React.FC = () => {
    const history = useHistory();
    useEffect(() => {
        if (history) {
            const notificationService = NotificationService.getInstance(history);
            notificationService.registerNotifications();
        }
    }, [history]);
    return (<></>);
}

export default Tabs;
