import {createAsyncAction, errorResult, registerInDevtools, Store, successResult} from "pullstate";
import {PulseViewModel} from "../viewModel/PulseView.model";
import {PulseServices} from "../services/PulseServices";
import {OrganizationStore} from "./OrganizationStore";


export interface PulseStoreInterface {
    pulses: PulseViewModel[]
}

export const PulseStore = new Store<PulseStoreInterface>({
    pulses: []
})
registerInDevtools({
    PulseStore
});


export namespace PulseStoreActions{

    export interface FindAllPulsesOutput {
        pulses?: PulseViewModel[]
    }

    export interface FindAllPulsesInput {
        eventId: number
    }
    export const findAllPulsesByEventId = createAsyncAction<FindAllPulsesInput, FindAllPulsesOutput>(
        async ({eventId}) => {
            try {
                const result = await PulseServices.findPulseListForEvent(eventId);

                // Transforming response to match PulseViewModel
                const transformedPulses = result.map((pulse: any) => ({
                    ...pulse,
                    pulseMessage: pulse.message, // Map message to pulseMessage
                }));

                PulseStore.update(s => {
                    s.pulses = transformedPulses;
                });

                return successResult({ pulses: transformedPulses });
            } catch (error) {
                return errorResult([], `Couldn't get pulses: ${error}`);
            }
        }
    );


}

//
// export interface AddPulseInput {
//     pulse: PulseAddBean.Model
// }
//
//
// export const addPulseAction = createAsyncAction<AddPulseInput, boolean>(
//     async ({pulse}) => {
//         try {
//             const result = await PulseServices.addPulse(pulse)
//
//             EventStore.update(e=> {
//                 let number = e.events.findIndex(ev => ev.eventId == pulse.eventId);
//                 e.events[number].totalPulses = e.events[number].totalPulses + 1;
//             })
//
//             return successResult(result);
//         } catch (error) {
//             return errorResult([], error as string);
//         }
//     }
// )


