import {ActionPerformed, PushNotifications, Token} from '@capacitor/push-notifications';
import {History} from 'history';
import {DeviceService} from './DeviceService';

export class NotificationService {
    private static instance: NotificationService;

    /**
     * This must be called within a router context where useHistory is available (nested under a ReactRouter tag)
     *
     * @param history a reference to useHistory from react-router
     * @returns a reference to this service, or a new instance if one does not yet exist
     */
    public static getInstance(history: History): NotificationService {
        if (!NotificationService.instance) {
            if (!!history) {
                NotificationService.instance = new NotificationService(history);
            }
        }
        return NotificationService.instance;
    }

    private notificationListenersSet: boolean;
    private history: History;

    private constructor(history: History) {
        this.notificationListenersSet = false;
        this.history = history;
    }

    public async registerNotifications() {
        let permStatus = await PushNotifications.checkPermissions();
        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }
        if (permStatus.receive !== 'granted') {
            throw new Error('User denied push notification permissions.');
        }
        await this.addNotificationListeners();
        await PushNotifications.register();
    }

    private async addNotificationListeners() {
        if (this.notificationListenersSet) {
            return;
        }
        this.notificationListenersSet = true;
        await PushNotifications.addListener('registration', async (token) => {
            console.info('registering device');
            await this.registerDevice(token);
        });
        await PushNotifications.addListener('registrationError', err => {
            console.error('Registration error:', err);
        });
        // await PushNotifications.addListener('pushNotificationReceived', notification => {
        //     console.log('Push notification received:');
        //     // console.log(notification);
        // });
        // note the bind on the function that allows history to be propagated to the callback
        await PushNotifications.addListener('pushNotificationActionPerformed', this.handlePushNotificationRouting.bind(this));
    }

    private handlePushNotificationRouting(notification: ActionPerformed) {
        // console.log('Push notification action performed', notification.actionId, notification);
        const {eventId, pulseId} = notification.notification.data as {pulseId: number, eventId: number};
        this.history.push(`/discover/event/${eventId}?pulse=${pulseId}`);
    }

    private async registerDevice(token: Token, seconds = 10): Promise<void> {
        try {
            await DeviceService.registerDevice(token.value);
        } catch (e) {
            console.error(e);
            return new Promise((resolve, reject) => {
                setTimeout(async () => {
                    await this.registerDevice(token, seconds * 2);
                    resolve();
                }, seconds * 1000);
            });
        }
    }
}
