import React, { useEffect, useState } from 'react';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonViewWillEnter
} from '@ionic/react';
import { chevronForward } from 'ionicons/icons';
import { EventUserListViewModel } from '../../viewModel/EventUserListViewModel';
import { EventStoreActions } from '../../store/EventStore';
import {PulseTrackerType} from '../../viewModel/MyEventsPulseTrackerView.model';
import eventEmitter from '../../utilities/EventEmitter';

const UserEventList: React.FC = () => {
    const [results, setResults] = useState<EventUserListViewModel[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const [pulseTracker, setPulseTracker] = useState<PulseTrackerType | null>(null);
    const lastCheckedTimeRef = React.useRef<Date | null>(null);
    const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

    const fetchNewPulses = async () => {
        try {
            const lastCheckedTime = lastCheckedTimeRef.current;
            if (lastCheckedTime) {
                const result = await EventStoreActions.findNewPulsesCountAction.run({ lastCheckedTime: lastCheckedTime.toISOString() });
                if (result.payload?.pulseTrackerResult) {
                    setPulseTracker(result.payload.pulseTrackerResult.pulseTracker);
                } else {
                    setPulseTracker({});
                }
                console.log(pulseTracker);
            }
        } catch (error) {
            console.error('Error fetching new pulses:', error);
        }
    };

    useEffect(() => {
        intervalRef.current = setInterval(fetchNewPulses, 15000); // 15 seconds

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    useIonViewWillEnter(() => {
        loadUserEvents();
        lastCheckedTimeRef.current = new Date(); // Set initial lastCheckedTime
    });

    const loadUserEvents = async () => {
        setError('');

        try {
            const res = await EventStoreActions.findAllEventsByUser.run();
            setResults(res.payload?.userSubscribedEvents || []);
        } catch (error) {
            console.error(error);
            setError('Error occurred while searching');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const trigger = (ignored: any) => loadUserEvents().then();
        eventEmitter.on('reload', trigger);
        return () => {
            eventEmitter.off('reload', trigger);
        }
    }, []);

    return (
        <>
            {loading && <p style={{ paddingLeft: '20px', paddingTop: '15px' }}>Loading your events...</p>}
            {error && <p style={{ paddingLeft: '20px', paddingTop: '15px' }}>{error}</p>}

            {!loading && !error && results.length === 0 && (
                <p style={{ paddingLeft: '20px', paddingTop: '15px' }}>
                    No event subscriptions! Search with the Discover tab to get started!
                </p>
            )}

            {!loading && !error && results.length > 0 && (
                <IonList>
                    {results.map((event) => {
                        const eventId = event.eventId;
                        const newPulseCount = pulseTracker?.[eventId] || 0;
                        console.log(pulseTracker, eventId, newPulseCount);

                        return (
                            <IonItem
                                key={eventId}
                                routerLink={`/discover/event/${eventId}`}
                                style={{ paddingLeft: '2px', marginRight: '-15px', display: 'flex', alignItems: 'center' }}
                            >
                                <IonLabel>
                                    <h2>{event.eventName}</h2>
                                    <p style={{paddingTop: '1px', color:'grey'}}>Event No.{event.eventNumber} &nbsp;- &nbsp;{event.pulseCount} pulse{event.pulseCount !== 1 ? 's' : ''}</p>
                                    <h3 style={{color:'grey'}}>{event.organizationName}</h3>
                                </IonLabel>
                                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                    {newPulseCount > 0 && (
                                        <span style={{ marginRight: '10px' }}>
                                            <IonButton color="tertiary">
                                                {newPulseCount} new pulses!
                                            </IonButton>
                                        </span>
                                    )}
                                    {!isPlatform('ios') && <IonIcon color="tertiary" icon={chevronForward} />}
                                </div>
                            </IonItem>
                        );
                    })}
                </IonList>
            )}
        </>
    );
};

export default UserEventList;
