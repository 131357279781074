
import {DocumentType} from "@aws-amplify/core/internals/utils";

export namespace Responses{

    export interface StandardResponse <Type>{body:Type, status:number, error:ErrorRecord}

    export interface ErrorRecord {
        errorCode:string,
        errorMessage:string
    }

    export function fromDocumentType<Type>(response:DocumentType){
        return response as any as StandardResponse<Type>
    }


}



