import { get } from "aws-amplify/api";
import { UserViewModel } from "../viewModel/UserView.model"; // Assuming you have a UserViewModel interface/class defined
import { Responses } from "../type/StandardResponse";

export namespace UserService {
    export async function whoAmI() {
        try {
            const op = get({
                apiName: 'API',
                path: '/who-am-i'
            });
            const {body} = await op.response;
            const result = Responses.fromDocumentType<UserViewModel>(await body.json());
            console.log(result);
            if (result.error) {
                throw new Error(result.error.errorMessage);
            }
            return result.body
        } catch (error) {
            throw error;
        }
    }
}
