import React, { useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonModal,
    IonRadioGroup,
    IonRadio,
    IonItem,
    IonLabel,
    IonCardSubtitle
} from "@ionic/react";
import { close } from "ionicons/icons";

interface SubscriptionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (type: string) => void;
    eventNumber: number;
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ isOpen, onClose, onSave, eventNumber }) => {
    const [selectedType, setSelectedType] = useState<string>("ALL");

    const handleSave = () => {
        onSave(selectedType);
    };

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose}>
            <IonCard color="secondary">
                <IonCardHeader>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <IonCardTitle>
                            <span style={{display: "flex"}}>
                                Select Subscription Type
                                <IonCardSubtitle>&nbsp;({eventNumber})</IonCardSubtitle>
                            </span>
                        </IonCardTitle>
                        <IonButton shape="round" fill="clear" mode="ios" color="light" style={{marginRight: "-15px"}}
                                   onClick={onClose}>
                            <IonIcon icon={close} style={{fontSize: "28px"}}/>
                        </IonButton>
                    </div>
                </IonCardHeader>
                <IonCardContent>
                    <IonRadioGroup value={selectedType} onIonChange={e => setSelectedType(e.detail.value)}>
                        <IonItem>
                            <IonLabel>All notifications, anywhere</IonLabel>
                            <IonRadio slot="start" value="ALL" />
                        </IonItem>
                        <IonItem>
                            <IonLabel>Notifications while I'm near the event</IonLabel>
                            <IonRadio slot="start" value="LOCAL" />
                        </IonItem>
                    </IonRadioGroup>
                    <IonButton expand="full" onClick={handleSave} color="tertiary">
                        Submit
                    </IonButton>
                </IonCardContent>
            </IonCard>
        </IonModal>
    );
};

export default SubscriptionModal;
