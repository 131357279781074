import {post} from 'aws-amplify/api';
import {DocumentType} from '@aws-amplify/core/dist/esm/libraryUtils';
import {Responses} from '../type/StandardResponse';

export namespace DeviceService {
    export async function registerDevice(deviceId: string): Promise<boolean> {
        const restOp = post({
            apiName: 'API',
            path: `/user-device/register`,
            options: {
                body: {deviceId} as any as DocumentType
            }
        });
        const {body} = await restOp.response;
        const result = Responses.fromDocumentType<boolean>(await body.json());
        if (result.error) {
            throw new Error(result.error.errorMessage);
        }
        return result.body;
    }
}
