import React, {BaseSyntheticEvent, useState} from 'react';
import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonInput,
    IonItem,
    IonList,
    IonModal, useIonToast
} from '@ionic/react';
import {close} from 'ionicons/icons';
import './PrivateEventModal.scss';
import {EventStoreActions} from '../../store/EventStore';
import eventEmitter from '../../utilities/EventEmitter';

interface PrivateEventModalProps {
    showPrivateEventModal: boolean;
    onDidDismiss: () => void;
}

enum PrivateEventModalExpectedErrors {
    INPUT_LENGTH,
    ADD_EVENT_USER_PRIVATE_ERROR,
    NO_PRIVATE_EVENT_FOR_CODE,
    ADD_EVENT_USER_PRIVATE_ERROR_MAX_SIZE,
    ADD_EVENT_USER_ERROR,
    ADD_EVENT_USER_PRIVATE_ERROR_USER_NOT_FOUND,
    ADD_EVENT_USER_PRIVATE_ERROR_USER_BANNED
}

const toPrivateEventModalExpectedErrors = (e: string) => {
    return PrivateEventModalExpectedErrors[e as keyof typeof PrivateEventModalExpectedErrors];
}

const PrivateEventModal: React.FC<PrivateEventModalProps> = (props) => {
    const MAX_LENGTH = 8;
    const MASK_CHARS = 3;
    const {showPrivateEventModal, onDidDismiss} = props;
    const [value, setValue] = useState('');
    const [maskedValue, setMaskedValue] = useState('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [currentError, setCurrentError] = useState<PrivateEventModalExpectedErrors|null>(null);

    const clearErrorMessage = () => {
        setCurrentError(null);
    };

    const getErrorMessage = () => {
        switch(currentError) {
            case PrivateEventModalExpectedErrors.INPUT_LENGTH:
                return 'Your code does not have exactly 8 characters.';
            case PrivateEventModalExpectedErrors.ADD_EVENT_USER_PRIVATE_ERROR:
            case PrivateEventModalExpectedErrors.ADD_EVENT_USER_ERROR:
            case PrivateEventModalExpectedErrors.ADD_EVENT_USER_PRIVATE_ERROR_USER_NOT_FOUND:
                return 'Something went wrong! Try again later.';
            case PrivateEventModalExpectedErrors.NO_PRIVATE_EVENT_FOR_CODE:
                return 'Your code is invalid. Please double-check it\'s correct.';
            case PrivateEventModalExpectedErrors.ADD_EVENT_USER_PRIVATE_ERROR_MAX_SIZE:
                return 'The event you\'re attempting to join is already at full.';
            case PrivateEventModalExpectedErrors.ADD_EVENT_USER_PRIVATE_ERROR_USER_BANNED:
                return 'You are not allowed to join this event.';
            default:
                return undefined;
        }
    }

    const handleChange = (event: BaseSyntheticEvent) => {
        clearErrorMessage();
        const newValue: string = ((event.target.value || '') as string).toUpperCase();
        const rawValue = newValue.replace(/[^A-Z0-9]/g, '');
        if (/^[A-Z0-9]*$/.test(rawValue) && rawValue.length <= 8) {
            setValue(rawValue);
            setMaskedValue(applyMask(rawValue));
        }
    }

    const applyMask = (value: string) => {
        return value.replace(/(.{4})(.{0,4})/, '$1 - $2');
    };

    const handleSubmit = () => {
        console.log(`Submitted Value: ${value}`);
        const currentValue = value.trim();
        if (currentValue.length !== 8) {
            setCurrentError(PrivateEventModalExpectedErrors.INPUT_LENGTH);
            return;
        }
        else {
            clearErrorMessage();
        }
        const bean = {
            code: currentValue,
            subscriptionType: 'ALL'
        }
        EventStoreActions.subscribeToPrivateEventAction.run(bean).then((result) => {
            console.log(result);
            if (result.error) {
                setCurrentError(toPrivateEventModalExpectedErrors(result.errorPayload.cause));
            } else {
                console.log(`Subscribed to private event with code: ${currentValue}`);
                eventEmitter.emit('reload', true);
                setShowSuccessAlert(true);  // Show the success alert
            }
        }).catch(error => {
            console.error(`Error subscribing to private event with code: ${currentValue}`, error);
            setCurrentError(PrivateEventModalExpectedErrors.ADD_EVENT_USER_ERROR);
        });
    }

    const handleSuccess = () => {
        setValue('');
        setMaskedValue('');
        setShowSuccessAlert(false);
        onDidDismiss();
    };

    return <IonModal isOpen={showPrivateEventModal} onDidDismiss={() => onDidDismiss()}>
        <IonCard color={'secondary'}>
            <IonCardHeader>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <IonCardTitle style={{fontSize:'1.5em'}}>Subscribe to Private Event</IonCardTitle>
                    <IonButton shape="round" fill="clear" mode="ios" color="light" style={{marginRight: '-15px'}} onClick={onDidDismiss}>
                        <IonIcon icon={close} style={{fontSize: '28px'}}/>
                    </IonButton>
                </div>
            </IonCardHeader>
            <IonCardContent>
                <IonList>
                    <IonItem className={currentError != null ? 'no-underline' : ''}>
                        <IonInput
                            value={maskedValue}
                            onInput={handleChange}
                            className={'event-code'}
                            maxlength={MAX_LENGTH + MASK_CHARS}
                            autocapitalize={'characters'}
                            placeholder="Enter 8-character invite code"
                        />
                    </IonItem>
                    {currentError != null && (
                        <IonItem className={'error'}>
                            <div>
                                {getErrorMessage()}
                            </div>
                        </IonItem>
                    )}
                    <IonItem button color={'primary'} className={'no-underline submit-item'} onClick={handleSubmit}>
                        <div>
                            Subscribe
                        </div>
                    </IonItem>
                </IonList>
            </IonCardContent>
        </IonCard>

        <IonAlert
            isOpen={showSuccessAlert}
            onDidDismiss={handleSuccess}
            message="Subscribed to event successfully!"
            buttons={['OK']}
        />
    </IonModal>
};

export default PrivateEventModal;