import React from "react";
import {RouteComponentProps, useLocation, useParams} from "react-router";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon, IonItem,
    IonPage, IonPopover,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import EventDescriptionCard from "../components/Events/EventDescriptionCard";
import PulseList from "../components/Events/PulseList";
import {EventStore} from "../store/EventStore";
import {reorderThreeOutline} from "ionicons/icons";

interface EventDetailProps extends RouteComponentProps<{ eventId: string; orgId: string;}> {}

const EventDetail: React.FC<EventDetailProps> = (props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pulseStr = queryParams.get('pulse');
    let pulseId;
    if (pulseStr && pulseStr.length > 0) {
        pulseId = parseInt(pulseStr, 10);
    }
    const { eventId, orgId } = useParams<{ eventId: string; orgId: string }>();
    const event = EventStore.useState((s) => s.events.find((o) => o.eventId === parseInt(eventId)));
    // const [started, finished, event, updating] = EventStore.selectorFindEventById.watch(parseInt(eventId));
    const [showPopover, setShowPopover] = React.useState<{ open: boolean, event: Event | null }>({ open: false, event: null });

    console.log("component loaded", eventId, orgId, event)
    if (!event) {
        return (
            <IonPage>
                <IonContent>Error!</IonContent>
            </IonPage>
        );
    }

    return (
        <>
        <IonPage style={{ overflow: 'hidden' }}>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton/>
                    </IonButtons>
                    <IonTitle className="ion-text-center">Event Details</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={(e) => setShowPopover({open: true, event: e.nativeEvent})}>
                            <IonIcon slot="icon-only" icon={reorderThreeOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen scrollY={false}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
                    <div style={{ flexShrink: 0 }}>
                        <EventDescriptionCard event={event} orgId={parseInt(orgId)} subscribed={event.subscribed} />
                    </div>

                    <div style={{ flexGrow: 1, overflowY: 'hidden' }}>
                        <PulseList eventId={event.eventId} pulseId={pulseId}/>
                    </div>
                </div>
            </IonContent>

        </IonPage>
            <IonPopover
                isOpen={showPopover.open}
            event={showPopover.event}
            onDidDismiss={() => setShowPopover({open: false, event: null})}
        >
            <IonItem button onClick={() => {
                const confirmReport = window.confirm('Are you sure you want to report this event?');
                if (confirmReport) {
                    setShowPopover({open: false, event: null});
                    return null;
                }
                setShowPopover({open: false, event: null});
            }}>
                &nbsp;&nbsp;Report this event
            </IonItem>
        </IonPopover>
        </>
    );
};

export default EventDetail;
