import {
    IonCard, IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import './MyEvents.css';
import UserEventList from "../components/Events/UserEventList";
import React from "react";
import PrivateEventCommonButton from '../components/PrivateEvents/PrivateEventCommonButton';

const MyEvents: React.FC = () => {



  return (
    <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <img src="/EP-logo.png" alt="Logo" className="logo" style={{ height: '32px' }} />
                <IonTitle class="ion-text-center">
                    My Events
                </IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="ion-padding">
            <IonCard color="secondary">
                <IonCardHeader>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <IonCardTitle>Subscribed Events</IonCardTitle>
                        <PrivateEventCommonButton />
                    </div>

                </IonCardHeader>
                <IonCardContent>
                    <UserEventList/>
                </IonCardContent>
            </IonCard>

        </IonContent>

    </IonPage>
  );
};

export default MyEvents;
